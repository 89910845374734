import React from "react"
import { Link } from "@hdir/ui"
import { faArrowRight } from "@fortawesome/pro-light-svg-icons"
import FontAwesomeIconWrapper from "../../../react4xp/shared/FontAwesomeIconWrapper/FontAwesomeIconWrapper"

import { ThemeBlockProps } from "./type.d";
import "./themeBlock.scss"

function ThemeBlock(props: Readonly<ThemeBlockProps>) {
  const { title, text, image, imageAlt, imagePlacement, links, editMode }: ThemeBlockProps = props;

  const imagePlacementClass = imagePlacement === 'left' ? 'flex-col tablet:flex-row' : 'flex-col-reverse tablet:flex-row-reverse';
  return (
    <>
      {(editMode && !title)
        ? (<div className="bg-surface-feedback-error p-md">Part "Temablokk med lenker" mangler innhold. Legg til en tittel for å vise noe her.</div>)
        : (
          <div className={`themeBlock flex py-md tablet:py-lg desktop:py-xl gap-lg tablet:gap-xl desktop:gap-xxxl ${imagePlacementClass} justify-between`}>
            {image && (
              <div className="w-full">
                <img className="w-full rounded-md" src={image} alt={imageAlt ?? ""} />
              </div>
            )}
            <div className="flex flex-col w-full break-normal">
              {title && (<h2 className="text-xxl font-bold hyphens-auto">{title}</h2>)}
              {text && (<p className="my-md tablet:my-lg text-md whitespace-pre-line">{text}</p>)}
              {links.length > 0 && links.map((elem, index) => (
                elem?.link && (
                  <Link key={index} className="my-xs shadow-none !w-full linkAnimation" variant="card" href={elem.link} target={elem.openInNewTab ? "_blank" : ""} >
                    <div className="flex self-stretch justify-between items-center gap-sm">
                      <p className="break-normal">{elem?.text}</p>
                      <FontAwesomeIconWrapper icon={faArrowRight} size="lg" className="iconAnimation mr-md text-action" />
                    </div>
                  </Link>
                )
              ))}
            </div>
          </div>
        )
      }
    </>
  );
};

export default (props: Readonly<ThemeBlockProps>) => <ThemeBlock {...props} />
